<template>
  <b-card>
    <div class="custom-grid-container">
      <b-form-group
        id="input-group-1"
        label="Hệ thống"
        label-for="input-1"
        class="grap-3"
      >
        <v-select :options="['Dịch vụ công Quốc gia']" />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Dịch vụ"
        label-for="input-1"
        class="grap-3"
      >
        <v-select :options="['Dịch vụ đồng bộ hồ sơ']" />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ngày đồng bộ từ"
        label-for="input-1"
        class="grap-3"
      >
        <date-picker
          value-type="format"
          format="DD/MM/YYYY"
          class="w-100 h-100"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Đến ngày"
        label-for="input-1"
        class="grap-3"
      >
        <date-picker
          value-type="format"
          format="DD/MM/YYYY"
          class="w-100 h-100"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
        class="grap-3"
      >
        <v-select :options="['Tất cả', 'Thành công', 'Thất bại']" />
      </b-form-group>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="compact ui btn-primary button"
        @click="openCommom"
      >
        <search-icon
          size="14"
          class="custom-class"
          :stroke-width="3"
        /> Tra cứu
      </button>
      <button
        class="compact ui btn-primary button"
      >
        <download-icon
          size="14"
          class="custom-class"
          :stroke-width="3"
        /> Kết xuất
      </button>
    </div>
    <app-table
      class="mt-1"
      :columns="columns"
      :table-data="tableData"
      @row-selected="onSelectRow"
    />
    <common-modal
      ref="commonModal"
      title="Chi tiết"
    >
      <div
        v-for="item in detailLabel"
        :key="item.id"
        class="custom-grid-container"
      >
        <div :class="item.classLeft">
          {{ item.title }}
        </div>
        <div :class="item.classRight">
          {{ columns && columns[0][item.field] }}
        </div>
      </div>
      <div slot="footer">
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="hideModal"
          >
            Đóng
          </b-button>
        </div>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import AppTable from '@/components/AppTable.vue'
import { SearchIcon, DownloadIcon } from 'vue-feather-icons'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    AppTable,
    DownloadIcon,
    SearchIcon,
    DatePicker,
    CommonModal,
    BButton,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới hỏi đáp',
      columns: [
        {
          field: 'stt',
          key: 'stt',
          title: 'STT',
          width: 50,
          align: 'center',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'name',
          key: 'name',
          title: 'Nội dung đồng bộ',
          align: 'left',
        },
        {
          field: 'people',
          key: 'people',
          title: 'Ngày đồng bộ',
          align: 'left',
        },
        {
          field: 'date',
          key: 'date',
          title: 'Trạng thái',
          align: 'left',
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Thao tác',
          align: 'right',
        },
      ],
      detailLabel: [
        {
          id: 1,
          title: 'Mã hồ sơ hệ thống khác: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: 'field',
        },
        {
          id: 2,
          title: 'Mã số biên nhận Một cửa: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 3,
          title: 'Tên hồ sơ: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 4,
          title: 'Mã TTHC: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 5,
          title: 'Tên TTHC: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 6,
          title: 'Nội dung log: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 7,
          title: 'Khoảng thời gian đồng bộ: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 8,
          title: 'Ngày đồng bộ: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 9,
          title: 'Loại đồng bộ: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 10,
          title: 'Request Header: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 11,
          title: 'Request Body: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 12,
          title: 'Response Header: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 13,
          title: 'Response Body: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 14,
          title: 'Trạng thái dữ liệu trả về: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 15,
          title: 'Loại thao tác: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
        {
          id: 16,
          title: 'Trạng thái: ',
          classLeft: 'grap-9',
          classRight: 'grap--9',
          field: '',
        },
      ],
      tableData: [],
    }
  },
  methods: {
    openCommom() {
      this.$refs.commonModal.showModal()
    },
    hideModal() {
      this.$refs.commonModal.hideModal()
    },
    onSelectRow(data) {
      this.$isDisabelBtn = data.length
    },
  },
}
</script>
