<template>
  <tra-cuu-log-dong-bo />
</template>
<script>
import TraCuuLogDongBo from '@/modules/danh-muc/components/pages/danh-muc/tien-ich/TraCuuLogDongBo.vue'

export default {
  components: {
    TraCuuLogDongBo,
  },
}
</script>
